var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AlgoliaOptionsComponent" },
    [
      _vm.isReady
        ? _vm._l(_vm.options, function (option) {
            return _c("ui-checkbox", {
              key: option[_vm.pathValue],
              staticClass: "d-block my-2",
              attrs: {
                value: option[_vm.pathValue],
                caption: _vm.label(option),
              },
              domProps: { values: _vm.selectedOptions },
              on: { changevalue: _vm.onChangeValue },
            })
          })
        : _c("ui-skeleton", { attrs: { rows: "4" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }