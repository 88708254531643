<script>
import without from 'lodash/without';
import { concatArrays, getValue } from '@emobg/web-utils';

export default {
  model: {
    prop: 'values',
    event: 'change',
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      required: true,
    },
    filters: {
      type: String,
      default: '',
    },
    label: {
      type: Function,
      default: option => option,
    },
    pathValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [],
      selectedOptions: [],
      isReady: false,
    };
  },
  async created() {
    await this.getAlgoliaOptions();
    this.selectedOptions = this.values;
  },
  methods: {
    async getAlgoliaOptions() {
      this.isReady = false;
      const algoliaResponse = await this.$algolia.fetchIndex(this.index, {
        filters: this.filters,
      });

      this.options = getValue(algoliaResponse, 'hits', []);
      this.isReady = true;
    },
    onChangeValue({ detail }) {
      this.selectedOptions = this.selectedOptions.includes(detail) ? without(this.selectedOptions, detail) : concatArrays(this.selectedOptions, [detail]);
      this.$emit('change', this.selectedOptions);
    },
  },
};
</script>
<template>
  <div class="AlgoliaOptionsComponent">
    <template v-if="isReady">
      <ui-checkbox
        v-for="option in options"
        :key="option[pathValue]"
        :value="option[pathValue]"
        :values.prop="selectedOptions"
        :caption="label(option)"
        class="d-block my-2"
        @changevalue="onChangeValue"
      />
    </template>
    <ui-skeleton
      v-else
      rows="4"
    />
  </div>
</template>
